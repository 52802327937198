@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    outline: none;
    scroll-behavior: smooth;
    font-family: "Poppins", sans-serif;
}

:root {
    --bg-color: #000022;
    --second-bg-color: #060642;
    --bg-opaque: #000022bb;
    --text-color: #ededed;
    --main-color: #E28413;
    --main-color-highlight: #ffee00;
    --main-color-dark: #d19143;
}

html {
    font-size: 62.5%;
    overflow-x: hidden;
}

body {
    background: var(--bg-color);
    color: var(--text-color);
}

header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 2rem 9%;
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    transition: 0.3s;
}

.header.sticky {
    backdrop-filter: blur(10px);
}

.logo {
    position: relative;
    font-size: 2.5rem;
    color: var(--text-color);
    font-weight: 600;
}
.navbar {
    position: relative;
}

.navbar a {
    font-size: 1.7rem;
    color: var(--text-color);
    font-weight: 500;
    margin-left: 3.5rem;
    transition: 0.3s;
}

.navbar a:hover,
.navbar a.active {
    color: var(--main-color);
}

#menu-icon {
    position: relative;
    font-size: 3.6rem;
    color: var(--text-color);
    cursor: pointer;
    display: none;
}

section {
    min-height: 100vh;
    padding: 10rem 9% 2rem;
    /* opacity: 0; */
}

.home {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 9%;
    /* background: url(./assets/img/monster.jpg) no-repeat;
    background-size: 50%;
    background-position: right; */
    overflow: hidden;
}

.home > img {
    height: 100%;
    max-height: 100vh;
    filter: brightness(0.7);
    opacity: 0.9;
}

.home::after {
    box-shadow: inset 118px -118px 98px 27px var(--bg-color);
    content: "";
    display: block;
    height: 101vh;
    position: absolute;
    top: 0;
    right: 0;
    /* width: 63%; */
    aspect-ratio: 839 / 1239;
}

.home-content {
    max-width: 67rem;
    z-index: 99;
}

#home-wave {
    display: inline-block;
}

.home-content h1 {
    position: relative;
    display: inline-block;
    font-size: 5.6rem;
    font-weight: 700;
    line-height: 1.3;
}

.home-content h1 span {
    color: var(--text-color);
}

.home-content .text-animate {
    position: relative;
    width: 32.8rem;
}
.home-content .text-animate h3 {
    font-size: 3.2rem;
    font-weight: 700;
    color: transparent;
    -webkit-text-stroke: 0.7px var(--main-color);
    background-image: linear-gradient(var(--main-color), var(--main-color));
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    background-position: -33rem 0;
}

.home.show-animate .home-content .text-animate h3 {
    animation: homeBgText 6s linear infinite;
    animation-delay: 2s;
}

.home-content .text-animate h3::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    border-right: 2px solid var(--main-color);
    z-index: -1;
}

.home.show-animate .home-content .text-animate h3::before {
    animation: homeCursorText 6s linear infinite;
    animation-delay: 2s;
}

.home-content p {
    position: relative;
    font-size: 1.6rem;
    margin: 2rem 0 4rem;
}

.btn-box {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 5rem;
    gap: 1rem;
}

.btn-box .btn {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 15rem;
    height: 100%;
    background: var(--main-color);
    border: 0.2rem solid var(--main-color);
    border-radius: 0.8rem;
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: 0.1rem;
    color: var(--bg-color);
    z-index: 1;
    overflow: hidden;
    transition: 0.5s;
}

.btn-box .btn:hover {
    color: var(--main-color);
}

.btn-box .btn:nth-child(2) {
    background: transparent;
    color: var(--main-color);
}

.btn-box .btn:nth-child(2):hover {
    color: var(--bg-color);
}

.btn-box .btn:nth-child(2)::before {
    background: var(--main-color);
}

.btn-box .btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: var(--bg-color);
    z-index: -1;
    transition: 0.5s;
}

.btn-box .btn:hover::before {
    width: 100%;
}

.home-sci {
    position: absolute;
    z-index: 1;
    bottom: 4rem;
    width: 170px;
    display: flex;
    justify-content: space-between;
}

.home-sci a {
    font-size: 2.6rem;
    color: #fff;
    text-decoration: underline;
}

.home-sci a:hover {
    text-decoration: none;
}

.home-imgHover {
    position: absolute;
    top: 0;
    right: 0;
    width: 45%;
    height: 100%;
    background: transparent;
}

.home-imgHover:hover {
    background: var(--bg-color);
    opacity: 0.8;
}

.about {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    /* background: var(--second-bg-color); */
    padding-bottom: 6;
}

.heading {
    position: relative;
    font-size: 5rem;
    margin-bottom: 3rem;
    text-align: center;
}

span {
    color: var(--main-color);
}

.about-img {
    position: relative;
    width: 42rem;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.about-img img {
    width: 90%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    border: 0.2rem solid var(--main-color);
}

.about-img .circle-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-top: 0.2rem solid var(--second-bg-color);
    border-bottom: 0.2rem solid var(--second-bg-color);
    border-left: 0.2rem solid var(--main-color);
    border-right: 0.2rem solid var(--main-color);
    animation: aboutSpinner 8s linear infinite;
}

.about-content {
    text-align: center;
}

.about-content h3 {
    position: relative;
    display: inline-block;
    font-size: 2.6rem;
}

.about-content p {
    position: relative;
    font-size: 1.6rem;
    margin: 2rem 0 3rem;
    max-width: 800px;
}

.btn-box.btns {
    display: inline-block;
    width: 15rem;
}

.btn-box.btns a::before {
    background: var(--second-bg-color);
}

.education {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 5rem;
    background: var(--second-bg-color);
}

.education .education-row {
    display: flex;
    flex-wrap: wrap;
    gap: 5rem;
    margin-top: 20px;
    width: 100%;
}

.education-row .education-column {
    flex: 1 1 45rem;
}

.education-column .title {
    position: relative;
    display: inline-block;
    font-size: 2.5rem;
    margin: 0 0 1.5rem 2rem;
}

.education-column .education-box {
    position: relative;
    border-left: 0.2rem solid var(--main-color);
}

.education-box .education-content {
    position: relative;
    padding-left: 2rem;
    visibility: hidden;
}

.education-box .education-content.content-active {
    visibility: visible;
}

.education-box .education-content::before {
    content: "";
    position: absolute;
    top: 0;
    left: -1.1rem;
    width: 2rem;
    height: 2rem;
    background: var(--main-color);
    border-radius: 50%;
}

.education-content .content {
    position: relative;
    padding: 1.5rem;
    border: 0.2rem solid var(--main-color);
    border-radius: 0.6rem;
    margin-bottom: 2rem;
    overflow: hidden;
    width: 105%;
    max-width: 80vw;
}

.education-content .content::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: var(--second-bg-color);
    z-index: -1;
    transition: 0.5s;
}

.education-content .content:hover::before {
    width: 100%;
}

.education-content .content .year {
    font-size: 1.5rem;
    color: var(--main-color);
    padding-bottom: 0.5rem;
}

.education-content .content .year i {
    padding-right: 0.5rem;
}

.education-content .content h3 {
    font-size: 2rem;
}

.education-content .content p {
    font-size: 1.6rem;
    padding-top: 0.5rem;
    /* white-space: nowrap; */
}

.skills {
    padding-bottom: 7rem;
    /* background: var(--second-bg-color); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.skills h2 {
    margin-bottom: 80px;
}

.skills .skills-row {
    display: flex;
    flex-wrap: wrap;
    gap: 5rem;
    width: 100%;
}

.skills-row .skills-column {
    flex: 1 1 40rem;
}

.skills-column .title {
    position: relative;
    display: inline-block;
    font-size: 2.5rem;
    margin: 0 0 1.5rem;
}

.skills-column .skills-box {
    position: relative;
}

.skills-box .skills-content {
    position: relative;
    border: 0.2rem solid var(--main-color);
    border-radius: 0.6rem;
    padding: 0.5rem 1.5rem;
    z-index: 1;
    overflow: hidden;
}

.skills-box .skills-content::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: var(--bg-color);
    z-index: -1;
    transition: 0.5s;
}

.skills-box .skills-content:hover::before {
    width: 100%;
}
.skills-content .progress {
    padding: 1rem 0;
}

.skills-content .progress h3 {
    font-size: 1.7rem;
    display: flex;
    justify-content: space-between;
}

.skills-content .progress h3 span {
    color: var(--text-color);
}

.skills-content .progress .bar {
    height: 2.5rem;
    border-radius: 0.6rem;
    border: 0.2rem solid var(--main-color);
    padding: 0.5rem;
    margin: 1rem 0;
}

.skills-content .progress .bar span {
    display: block;
    height: 100%;
    border-radius: 0.3rem;
    background: var(--main-color);
    visibility: hidden;
}
.skills-content .progress .bar span.bar-active {
    visibility: visible;
}

.skills-column:nth-child(1) .skills-content .progress:nth-child(1) .bar span {
    width: 20%;
}

.skills-column:nth-child(1) .skills-content .progress:nth-child(2) .bar span {
    width: 20%;
}

.skills-column:nth-child(1) .skills-content .progress:nth-child(3) .bar span {
    width: 10%;
}

.skills-column:nth-child(1) .skills-content .progress:nth-child(4) .bar span {
    width: 5%;
}

.skills-column:nth-child(2) .skills-content .progress:nth-child(1) .bar span {
    width: 95%;
}

.skills-column:nth-child(2) .skills-content .progress:nth-child(2) .bar span {
    width: 75%;
}

.skills-column:nth-child(2) .skills-content .progress:nth-child(3) .bar span {
    width: 95%;
}

.skills-column:nth-child(2) .skills-content .progress:nth-child(4) .bar span {
    width: 60%;
}

.contact {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    padding-bottom: 5rem;
    background: var(--second-bg-color);
    padding: 10rem 2% 2rem;
    min-height: 100vh;
    overflow: hidden;

    & .heading {
        position: absolute;
        top: 120px;
    }


}

.contact * {
    box-sizing: unset;
}

.contact-mail-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 700px;
}

.contact-mail-wrapper .contact-mail-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-mail-wrapper a {
    padding: 10rem;
    background-color: var(--bg-color);
    border-radius: 5rem;
    color: var(--text-color);
    font-size: 80px;
    transition: all 0.2s ease-out;
    border: solid var(--main-color) 0px;
}

.contact-mail-wrapper a:hover {
    color: var(--main-color);
    scale: 1.1;
    text-decoration: underline;
    /* letter-spacing: 10px; */
    padding: 9rem;
    font-size: 100px;
    /* border-radius: 5px; */
    font-weight: 700;
    border-width: 20px;
}

.contact-mail-wrapper .mail-icon {
    position: absolute;
    width: 150px;
    height: 150px;
    font-size: 100px;
    pointer-events: none;
    z-index: -1;
}

.contact-mail-wrapper .sparkle {
    position: absolute;
    width: 800px;
    pointer-events: none;
    z-index: -2;
}

.sparkle-blur {
    filter: blur(100px);
    width: 1200px!important;
    z-index: -3;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem 9%;
    background: var(--second-bg-color);
}

.footer-text,
.footer-iconTop {
    position: relative;
}

.footer-text p {
    font-size: 1.6rem;
}

.footer-text a {
    font-size: 1.6rem;
    color: var(--main-color);
    transition: 0.3s;
}

.footer-text a:hover {
    color: var(--main-color-highlight);
}

.footer-iconTop a {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem;
    background: var(--main-color);
    border: 0.2rem solid var(--main-color);
    border-radius: 0.6rem;
    z-index: 1;
    overflow: hidden;
}

.footer-iconTop a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: var(--second-bg-color);
    z-index: -1;
    transition: 0.5s;
}

.footer-iconTop a:hover::before {
    width: 100%;
}

.footer-iconTop a i {
    font-size: 2.4rem;
    color: var(--bg-color);
    transition: 0.5s;
}

.footer-iconTop a:hover i {
    color: var(--main-color);
}

/* ANIMATION RELOAD AND SCROLL */

.animate {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: var(--bg-color);
    z-index: 98;
}

.animate.home-img {
    width: 50%;
}

.logo .animate,
.navbar .animate,
#menu-icon .animate,
.home.show-animate .animate {
    animation: showRight 1s ease forwards;
    animation-delay: calc(0.3s * var(--i));
}

.animate.scroll {
    transition: 1s ease;
    transition-delay: calc(0.3s / var(--i));
    animation: none;
}

section:nth-child(odd) .animate.scroll,
.footer .animate.scroll {
    background: var(--second-bg-color);
}

.education .education-box .animate.scroll {
    width: 105%;
}

.about.show-animate .animate.scroll,
.education.show-animate .animate.scroll,
.skills.show-animate .animate.scroll,
.contact.show-animate .animate.scroll,
.footer.show-animate .animate.scroll {
    transition-delay: calc(0.3s * var(--i));
    width: 0;
}

#datenschutz-open {
    cursor: pointer;
}

#datenschutz {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: transparent;
    /* backdrop-filter: blur(10px); */
}

#datenschutz::backdrop {
    background: var(--bg-opaque);
    backdrop-filter: blur(10px);
}

#datenschutz .datenschutz-outer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#datenschutz .datenschutz-inner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 50px 0px 0px 50px;
}

#datenschutz .datenschutz-close {
    font-size: 5rem;
    position: absolute;
    top: 20px;
    right: 40px;
    color: var(--main-color);
    cursor: pointer;
    font-weight: 700;
    transition: 0.3s;
    transform: rotate(180deg);
}

#datenschutz .datenschutz-close:hover {
    color: var(--text-color);
    transform: rotate(0deg) scale(1.2);
}

#datenschutz .datenschutz-inner h2 span {
    font-size: 60px;
}

#datenschutz .datenschutz-text {
    font-size: 20px;
    margin: 20px 0;
    overflow-y: auto;
    height: 100%;
    color: var(--text-color);
}

/* BREAKPOINTS */

@media (max-width: 1700px) {
    .contact-mail-wrapper a {
        font-size: 40px;
    }
    .contact-mail-wrapper a:hover {
        font-size: 50px;
    }
    .contact-mail-wrapper {
        height: 500px;
    }
}

@media (max-width: 1200px) {
    html {
        font-size: 55%;
    }
}

@media (max-width: 991px) {
    .header {
        padding: 2rem 4%;
    }

    section {
        padding: 10rem 4% 2rem;
    }

    .home {
        padding: 0 4%;
        flex-direction: column;
        justify-content: center;
        align-items: revert-layer;
    }

    section.home > img {
        position: absolute;
        z-index: 0;
        filter: brightness(0.5);
        background-blend-mode: color-dodge;
        opacity: 0.8;
        width: 100vw;
        left: 0;
        height: unset;
        max-height: unset;
        top: 0;
    }

    section.home::after {
        display: none !important;
    }

    .footer {
        padding: 2rem 4%;
    }

    .contact-mail-wrapper {
        height: 400px;
    }

    .contact-mail-wrapper a {
        padding: 5rem;
        
    }
    
    .contact-mail-wrapper a:hover {
        font-size: 40px;
        border-width: 5px;
        padding: 7rem;
    }

    .mail-icon {
        height: 75px!important;
        width: 75px!important;
        font-size: 50px!important;
    }

}

@media (max-width: 850px) {
    .animate.home-img {
        width: 55%;
    }
}

@media (max-width: 768px) {
    #menu-icon {
        display: block;
    }

    .navbar {
        position: absolute;
        top: 100%;
        left: -100%;
        width: 100%;
        padding: 1rem 4%;
        background: var(--main-color);
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
        z-index: 1;
        transition: 0.25s ease;
        transition-delay: 0.25s;
    }

    .navbar.active {
        left: 0;
        transition-delay: 0s;
    }

    .navbar .active-nav {
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: var(--bg-color);
        border-top: 0.1rem solid rgba(0, 0, 0, 0.2);
        z-index: -1;
        transition: 0.25s ease;
        transition-delay: 0s;
    }

    .navbar.active .active-nav {
        left: 0;
        transition-delay: 0.25s;
    }

    .navbar a {
        display: block;
        font-size: 2rem;
        margin: 3rem 0;
        transform: translateX(-20rem);
        transition: 0.25s ease;
        transition-delay: 0s;
    }

    .navbar.active a {
        transform: translateX(0);
        transition-delay: 0.25s;
    }

    .home-imgHover {
        pointer-events: none;
        background: var(--bg-color);
        opacity: 0.6;
    }

    .contact-mail-wrapper a {
        font-size: 20px;
        
    }

    .contact-mail-wrapper a:hover {
        font-size: 24px;
    }

    .contact-mail-wrapper {
        height: 250px;
    }
}

@media (max-width: 520px) {
    html {
        font-size: 50%;
    }
    .home-contnet {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 462px) {
    .home-content {
        font-size: 5.2rem;
    }
    .education {
        padding: 10rem 4% 5rem 5%;
    }

    contact form .input-box .input-field {
        width: 100%;
    }

    .footer {
        flex-direction: column;
    }

    .footer p {
        margin: 2rem;
        text-align: center;
    }
}

@media (max-width: 371px) {
    .home {
        justify-content: center;
    }

    .home-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }

    .home-content h1 {
        font-size: 5rem;
    }
}

/* KEYFRAMES ANIMATION */

@keyframes homeBgText {
    0%,
    10%,
    100% {
        background-position: -33rem 0;
    }
    65%,
    85% {
        background-position: 0 0;
    }
}

@keyframes homeCursorText {
    0%,
    10%,
    100% {
        width: 0;
    }
    65%,
    78%,
    85% {
        width: 100%;
        opacity: 1;
    }
    75%,
    81% {
        opacity: 0;
    }
}

@keyframes aboutSpinner {
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes showRight {
    100% {
        width: 0;
    }
}
